.news-listing {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
  .news-box {
    background: #fff;
    margin-right: 40px;
    margin-bottom: 50px;
    padding: 10px;
    width: calc(100% / 3 - 40px);
    position: relative;
    h2 {
      font-size: 20px;
      font-weight: 400;
      text-align: left;
      margin-bottom: 0px;
      margin-left: 0px;
      height: 50px;
      line-height: 30px;
    }
    .date {
      font-size: 12px;
      text-align: right;
    }
    hr {
      margin-top: 0px;
    }
    .bild {
      img {
        width: 100%
      }
    }
    .text {
      margin-bottom: 40px;
      margin-top: 10px;
      height: 160px !important;
      font-size: 15px !important;
      overflow: hidden;
    }
    .details {
      justify-content: flex-end;
      border-top: 1px solid rgba(0,0,0,0.1);
      display: flex;
      position: absolute;
      width: calc(100% - 20px);
      bottom: 5px;
      left: 10px;
      font-weight: bold;
      a {
        color: $navbar--color-active;
        opacity: 1;
        transition: opacity 0.3s;
      }
      a:hover {
        opacity: 0.8;
      }
      i {
        margin-left: 10px;
        svg, img {
          width: 20px;
        }
      }
    }
    .content--shadow {
      width: 100%;
      position: absolute;
      bottom: 0px;
      top: unset !important;
      margin-left: -10px;
      picture {
        width: 100%;
        img {
          width: 100% !important;
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .news-listing {
    .news-box {
      width: calc(100% / 2 - 20px);
    }
    .news-box:nth-child(even) {
      margin-right: 0px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .news-listing {
    .news-box {
      width: 100% !important;
      margin-right: 0px;
    }
  }
}
