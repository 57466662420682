.gallery {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  z-index: 99;
  .gallery-prev {
    position: relative;
    width: calc(50%);
    overflow: hidden;
    .gallery-name {
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 70px;
      width: 100%;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      color: #ffffff;
      text-shadow: 0px 0px 5px #000;
      flex-wrap: wrap;
      display: flex;
      z-index: 99;
      justify-content: center;
      vertical-align: bottom;
      background-image: linear-gradient(180deg, rgba(212,0,60,0) 0%,rgba(255,255,255,0) 50%);
      background: #999;
      cursor: pointer;
    }
    .gallery-name::before {
      position: absolute;
      content: "";
      top: 0px;
      left: 0px;
      //background-image: linear-gradient(180deg, rgba(212,0,60,0.9) 0%,rgba(255,255,255,0) 30%);
      z-index: 2;
      transition: opacity 0.3s linear;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .gallery-name:hover::before {
      opacity: 1;
    }
    picture {
      position: relative;
      width: 100%;
      z-index: 2;
      img {
        width: 100%;
        transition: filter 0.3s;
      }
    }
    a:hover {
      picture {
        img {
          filter: brightness(0.9) contrast(1.3);
        }
      }
    }
  }
  .gallery-pic {
    position: relative;
    width: calc(100% / 6);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    a {
      width: calc(100% - 20px);
      margin: 10px;
      img {
        width: 100%;
      }
    }
  }
}