.iconlist {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  a {
    display: flex;
    cursor: default;

    .fontawsome-icon {
      width: 50px;
      height: 50px;
      background: #878787;
      border-radius: 0.25rem;
      margin: 5px;
      padding: 5px;

      svg {
        * {
          stroke: #ffffff;
        }
      }
    }
  }
}

.appartment-item-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .appartment-item {
    display: flex;
    flex-direction: row;
    width: calc(100% / 3);
    .icon {
      margin-right: 10px;
      a {
        display: flex;

        .fontawsome-icon {
          width: 40px;
          height: 40px;
          background: #878787;
          border-radius: 0.25rem;
          margin: 5px;
          padding: 5px;

          svg {
            * {
              stroke: #ffffff;
            }
          }
        }
      }
    }
    .description {
      p {
        margin-top: 10px;
      }
    }
  }
}