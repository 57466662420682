.teaser {
  width: 100%;
  height: calc(100vh - 130px);
  min-height: 500px;
  position: relative;
  overflow: hidden;
  margin-top: -18px;
  .embed-responsive {
    object-fit: cover;
    height: calc(100vh);
    video {
      object-fit: cover;
    }
  }

  .teaser--bg {
    width: 100%;
    height: calc(100vh - 80px);
    position: absolute;
    opacity: 0;
    transition: opacity 0.9s;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .active {
    opacity: 1;
  }

  .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 30px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999;
  }

  article {
    position: absolute;
    bottom: 10px;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px;

    h1 {
      font-size: 60px;
      color: #fff;
      font-weight: 600;
      text-align: center;
      border-bottom: 0px !important;
      margin-left: 0px;
      transition: margin-left ease-in 1.5s, opacity ease-in 1.5s;
      opacity: 0;
    }

    p {
      font-size: 34px;
      //text-shadow: rgba(0, 0, 0, 0.9) 1px 1px 10px;
      color: #ccc;
      opacity: 1;
      text-align: center;
      opacity: 0;
      margin-left: 50px;
      transition: margin-left ease-in 1.5s, opacity ease-in 1.5s;
    }

    .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: theme-color('primary');
      border-radius: 15px;
    }

    .fade-in {
      opacity: 0;
    }
  }

  .fade-rtl, .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0;
  }

  .fade-rtl {
    transform: translate(50px);
  }

  .fade-ltr {
    transform: translate(-50px);
  }

  #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease;
  }
  .active {
    article {
      h1 {
        margin-left: 0px;
        opacity: 1;
        //text-shadow: 0px 0px 5px rgba(0,0,0,0.7);
      }
      p {
        margin-left: 10px;
        opacity: 0.9;
      }
    }
  }
}

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: relative;
  box-shadow: 0px -7px 10px rgba(0,0,0,0.5)!important;
}

.teaser--main {
  overflow: hidden;
  position: relative;

  .rohr {
    background: linear-gradient(to right, #e3cdae 0%,#fff8ee 40%,#ffffff 45%,#fff8ee 50%,#aea598 80%);
    width: 25px;
    height: 100%;
    position: absolute;
    top: 0px;
    right: 10%;
    z-index: 9999;
    transform: translateX(50%);
  }

  .logo-only {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    picture {
      img {
        width: 250px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .teaser--main {
    .logo-only {
      picture {
       img {
         width: 125px;
       }
      }
    }
  }
}

.schalter {
  position: absolute;
  cursor: pointer;
  width: 150px;
  height: 0px;
  right: 10%;
  z-index: 999;
  transform: translate(50%, -10px);
  img {
    width: 100%;
    z-index: 9999;
  }
  .schalter-clicked {
    width: 84px;
    top: 76px;
    right: 33px;
    position: absolute;
    display: none;
    img {
      width: 100%;
    }
  }
  .rohr {
    background: linear-gradient(to right, #e3cdae 0%,#fff8ee 40%,#ffffff 45%,#fff8ee 50%,#aea598 80%);
    width: 25px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 10%;
    z-index: 9999;
    transform: translate(50%);
    right: 50%;
  }
}
