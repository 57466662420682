.content--shadow-footer {
  position: absolute;
  bottom: 0px;
  width: 100vw;
  transform: translate(0px, 0%);
  picture {
    position: relative;
    width: 100%;
    left: 0px;
    top: 0px;
    img {
      width: 100%;
      position: relative;
      transform: translateX(-50%);
      filter: brightness(1) !important;
      left: 50%;
      z-index: 999;
    }
  }
}


footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px !important;
  position: relative;
  width: 100%;
  background: #d4003c !important;
  min-height: 50px;
  color: #fff;
  @include media-breakpoint-down(lg) {
    .logo-footer {
      img {
        width: 230px;
      }
    }
  }
  .texture {
    width: 100%;
    padding: 0px;
    position: relative;
    overflow: hidden;
    min-height: 120px;

    .grid-container {
      padding-top: 100px;
      h1, h2, p {
        color: #ffffff !important;
        text-shadow: -1px -1px 0px #000000;
      }
    }
  }

  .bg-white {
    width: 100%;
    position: relative;
    text-align: center;
    padding-bottom: 1px;
    padding-top: 10px;
    .grid-container {
      .container-fluid {
        padding-top: 10px;
      }
    }

    .links {
      font-size: 20px;
      font-family: "Roboto Condensed";
      font-weight: 600;
      color: #d4003c;
      margin-bottom: 10px !important;
      a {
        color: #d4003c;
        transition: opacity 0.3s;
      }
      :hover {
        opacity: 0.5;
      }
    }
    a {
      color: #d4003c;
      transition: opacity 0.3s;
    }
    a:hover {
      opacity: 0.5;
    }
    @include media-breakpoint-down(lg) {
      .links {
        font-size: 20px;
        padding-bottom: 30px;
      }
    }
    .copyright {
      font-family: "Roboto Condensed";
      font-size: 15px;
      font-weight: 400;
      color: #d4003c;
    }
  }
  .content--shadow-footer, .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%;
    picture {
      position: absolute;
      width: 100%;
      left: 0px;
      top: 0px;
      img {
        width: 100%;
        position: absolute;
        transform: translateX(-50%);
        filter: brightness(1) !important;
        left: 50%;
        z-index: 999;
      }
    }
  }
  .socialmediaicons {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    i {
      display: block;
    }
      svg {
        color: #fff;
        width: auto;
        height: 50px;
      }
  }
}