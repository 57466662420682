@include media-breakpoint-down(lg) {
  body {
    .logo-brauhaus {
      picture {
        img {
          max-height: 90px;
        }
      }
    }
    .header {
      bottom: unset;
      top: 0px !important;
      max-height: 80px !important;
    }
    .navbar {
      .navbar-nav {
        flex-direction: column;
      }
      .navbar-collapse {
        background: #ffffff;
        left: 0px;
        top: 50px !important;
        box-shadow: 0 7px 7px -7px #000000;
        .navbar-nav {
          margin-left: 0px;
          padding-left: 0px;
        }
      }
      .socialmediaicons {
        position: fixed;
        top: 27px !important;
        i {
          color: #fff !important;
        }
      }
      .navbar-toggler {
        top: 15px !important;
        border-color: #fff;
      }
    }
    .navbar-light {
      .navbar-toggler {
        color: #fff !important;
      }
      .navbar-toggler-icon {
        background: $navbar-dark-toggler-icon-bg;
      }
    }

    .bilder--galerie {
      .bilder--slider {
        height: calc(100vw / 16 * 8);
      }
    }
  }
  .download a {
    width: calc(70% - 10px);
  }
}