.download {
  position: relative;
  display: flex;
  flex-direction: row;
  a {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    width: calc(60% - 10px);
    div {
      margin-right: 10px;
    }

    .icon {
      width: 180px;
      margin-right: 30px;
      svg, img {
        border: 1px solid #ccc;
        width: 150px;
        color: $navbar--color-active;
      }
    }
    .headline {
      width: calc(100% - 180px - 90px);
    }
    .size {
      width: 90px;
    }
  }
}