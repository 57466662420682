.team-modul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  flex-wrap: wrap;
  .box {
    background: #efefef;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-bottom: 60px;
    padding-bottom: 10px;
    position: relative;
    align-self: flex-start;
    width: calc(100% / 3 - 10px);
    height: 550px;
    .name, .qualifikation, .funktion {
      width: 100%;
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      color: #868686;
    }
    .qualifikation {
      font-size: 17px;
      color: #333333;
      font-weight: 400;
      height: 40px;
    }
    .funktion {
      font-size: 17px;
      font-weight: 400;
      color: #333333;
    }
    .bild {
      padding: 15px;
      height: 360px;
      overflow: hidden;
      picture {
        img {
          width: 100%;
        }
      }
    }
    .text {
      padding: 0px 15px;
      color: #333333;
      h1 {
        font-size: 40px;
        color: #868686;
      }
    }
    .fontawsome-icon {
      display: flex;
      justify-content: center;
      svg,img {
        height: 50px;
      }
    }
    .kontakt {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 5px;
      justify-content: space-evenly;
      min-height: 50px;
      a {
        width: calc(100% - 10px);
        margin: 0px 5px;
        transition: color 0.3s;
        font-size: 15px;
        color: $navbar--color-active !important;
      }
      a:hover {
        color: $navbar--color-active !important;
      }
    }

    hr {
      width: calc(100% - 30px);
      margin-right: 15px;
      margin-left: 15px;
    }

    .schatten-box {
      position: absolute;
      width: 100%;
      bottom: 0px;

      img {
        width: 100%;
        position: absolute;
        bottom: 0px;
        height: 20px;
        transform: translateY(100%);
      }
    }
  }
  .box:nth-last-child {
    margin-right: 0px;
  }
}
.container-color-2 {
  .team-modul {
    .box {
      background: #efefef!important;
    }
  }
}

@include media-breakpoint-down(md) {
  .team-modul {
    .box {
      width: calc(100% / 2 - 10px) !important;
    }
  }
}


@include media-breakpoint-down(sm) {
  .team-modul {
    .box {
      width: 100% !important;
    }
  }
}