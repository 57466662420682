@include media-breakpoint-down(xs) {
  body {
    font-size: 16px !important;
    .logo-brauhaus {
      picture {
        img {
          max-height: 70px;
        }
      }
    }
    .bilder-slider-preview {
      bottom: 0px !important;
      height: 43px !important;
      picture {
        height: 40px !important;
        max-height: 40px !important;
        img {
          border: 1px solid #fff !important;
        }
      }
    }
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 20px !important;
    }
    .navbar {
      top: 0px;
      box-shadow: 0 -10px 20px 10px #000;
      height: 50px;

      .logo-page {
        height: 40px;
      }

      .navbar-toggler {
        border: none;
        box-shadow: none;
      }

      .navbar-nav {
        height: auto;
        font-size: 25px;
        .nav-item {
          min-height: 35px;
          flex: unset;
          flex-direction: column;
          a {
            width: 100vw;
            text-align: center;
            justify-content: center;
          }
          .dropdown-menu {
            a {
              font-size: 20px;
            }
          }
        }
      }
      .navbar-collapse {
        position: fixed;
        top: 80px;
        width: 100vw;
      }
      .nextdate {
        display: none;
      }
    }
    .content {
      margin-top: -50px;
    }

    .has-open {
      width: 280px;
      height: 50px;
      transform: translate(-50%, -50px);
      .clock {
        width: 50px;
        height: 30px;
        i {
          font-size: 30px;
        }
      }
      .text {
        font-size: 20px;
        margin-top: 11px;
      }
      .zeit {
        font-size: 16px;
        width: 80px;
        margin-top: 11px;
      }
    }

    .has-closed {
      width: 210px;
      height: 50px;
      transform: translate(-50%, -50px);
      .clock {
        width: 50px;
        height: 30px;
        i {
          font-size: 30px;
        }
      }
      .text-closed {
        font-size: 20px;
        margin-top: 11px;
      }
      .zeit {
        font-size: 16px;
        width: 80px;
        margin-top: 11px;
      }
    }

    .bildmittext-box {
      img {
        max-width: 100% !important;
        margin-bottom: 20px !important;
      }
    }
  }

  .tages-special {
    h2 {
      font-size: 30px !important;
    }
    h3 {
      font-size: 25px !important;
    }
    h4 {
      font-size: 85px !important;
    }
    .picture {
      margin-top: 70px !important;
    }
  }

  footer {
    .wood {
      .holz {
        img {

        }
      }
      .footer--menu {
        flex-direction: column !important;
        display: none !important;
      }
      .logo-footer {
        width: 50%;
        left: 50%;
        position: relative;
      }

      .socialmediaicons-footer {
        i {
          font-size: 35px;
        }
      }
    }
  }
}