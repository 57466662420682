@include media-breakpoint-down(sm) {

  body {
    .navbar {
      .navbar-toggler {
        transform: translateY(-10px);
      }
      .socialmediaicons {
        opacity: 0;
        width: calc(100vw - 70px);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        top: 7px !important;
        a {
          i {
            margin: 0px 10px 10px 0px;
            svg, img {
              height: 35px;
            }
          }
        }
      }
    }
    .teaser--main {
      height: 290px !important;

      .teaser {
        margin-top: 0px;
        min-height: 320px !important;
        max-height: 320px !important;
      }
      .teaser--bg {
        height: 110% !important;
        article {
          padding-left: 10px;
          padding-right: 10px;
          top: 40%;

          h1 {
            font-size: 40px;
            margin-top: 0px;
          }

          p {
            font-size: 20px;
            font-weight: 600;
          }
        }
      }
    }
    .teaser--shadow {
      box-shadow: 0px -3px 7px rgba(0, 0, 0, .5) !important;
    }
    .kontakticons {
      a {
        height: 50px;
        i {
          padding: 0px;
          width: 60px;
          display: flex;
          justify-content: center;
          img,svg {
            height: 50px;
          }
        }
        .text {
          display: none;
        }
      }
    }
    footer {
      .kontakticons {
        margin-bottom: 100px !important;
        a {
          height: 50px;
          i {
            padding: 0px;
            width: 60px;
            display: flex;
            justify-content: center;
            img,svg {
              height: 50px;
            }
          }
          .text {
            display: none;
          }
        }
      }
      .texture {
        .col {
          margin-bottom: 30px;
        }
      }
      .socialmediaicons {
        min-height: 150px;
      }
    }
  }
  .download a {
    width: 100%;

    .icon {
      width: 130px;
    }
    .headline {
      width: calc(100% - 130px - 90px);
    }
  }
}